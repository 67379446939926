import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { Title } from '@angular/platform-browser';
// import { DataService } from '../services/DataService';
// import { AuthService } from '../services/auth/auth.service';
import { StaticContentService } from '../services/StaticContentService';

import { UserService } from '../services/UserService';
// import { AuthService } from '../services/auth/auth.service';
import { Globals } from '../globals';
import * as $ from 'jquery';
// import { RightOutSelDirective } from 'ng5-slider/slider.component';
import { ErrorNotificationService } from '../services/ErrorNotificationService';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalGuardService } from '../services/msal/msal-guard.service';
import { MsalService } from '@azure/msal-angular';

let env: any;
try{
    env = require('../../../env.json');
}catch{
    
}

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css'],
  providers: [ StaticContentService, MsalGuardService]
})
export class LandingpageComponent implements OnInit {

  public unAuthorizedMessage: string = 'Unauthorized access. Please contact the administrator';
  public appMessage: string = 'Loading application. Please wait...';
  public hasError: boolean = false;
  public isVisible: boolean = false;
  public isProcessing: boolean = false;
  formerEmployeeName: string;
  formerEmployeeValue: string;
  loading = true;
  staticContent: any;
  legalNotice: string;
  inAppMsg: any;
  inAppMsgDate: any;
  message: any;
  messageA: any;
  messageB: any;
  messageC: any;
  showInApp: boolean;  
  bellGrantList: any;
  privacyPolicyUserAgreement: boolean;
  isProceedButtonDisabled: boolean = true;
  resourcesData;
  myResourcesData: any = [];
  data: any;
  errorLocation: string = " + [Component: Landingpage, Method: methodName]";  
  MSlink: any;
  envSetting: any;
  UBSlink: any;


  selectedEmployeeType: string;
  portalLink: string;
  showLanding: boolean = true;

  constructor(private authService: MsalService, private msalService: MsalGuardService, private sanitizer:DomSanitizer, private userService: UserService,private globals: Globals, private titleService: Title, private router: Router, private staticContentService: StaticContentService, private errorNotificationService: ErrorNotificationService) { 
    if(sessionStorage.getItem('msal.idtoken')) {
      this.navToPage('homepage');
    }
    if(sessionStorage.getItem("msal.2cb02e3a-1122-49eb-85e4-9fad772b2b03.request.origin") || sessionStorage.getItem("msal.77d56f01-b168-4ff1-953d-c5176edc69f9.request.origin")) {
      this.showLanding = false;
      console.log("No landing display...");
    }
  }
  
  /**********************************************************************************
      Method Name: ngOnInit method
      Description: Initialize page

      Date            Modified by       Description    
      03/11/19        Roldan Mazo       Modify Initialization with In-App Data
      3/12/2019       Roldan Mazo       Modify In-App, include Null in the condition
  **********************************************************************************/

  ngOnInit() {
    // this.checkErrorMessage();
  //   if(sessionStorage.getItem("GDPRdisagree") != "yes"){
  //   sessionStorage.setItem(this.globals.profileInfoKey, JSON.stringify(null));
  // }
    if(sessionStorage.getItem("GDPRdisagree")=="yes")
    sessionStorage.setItem("GDPRdisagree","fromLandingPage");
    else(sessionStorage.getItem("GDPRdisagree")!="yes")
    sessionStorage.setItem(this.globals.profileInfoKey, JSON.stringify(null)); 
    
    this.staticContentService.setNavLoginState(true);
    
    this.getBluePageStaticContent();
    this.getFooterStaticContent();    
    //this.getInAppNotificationContent();   
    this.getDatafrommyResources(); 
    setTimeout(()=>
    this.setNavbarSticky()
    ,500);
    if(sessionStorage.getItem("footerSetting") == "old"){
      $( "#thePageFooter" ).css("display","none");
      $( "#thePageFooterOld" ).css("display","none");
    }
  }

  navToPage(strLoc){
    try {
      if(strLoc == "technologysupport"){
        sessionStorage.setItem("footerSetting","old");
        this.router.navigate([strLoc]);
      }else
        this.router.navigate([strLoc]);
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: getFooterStaticContent
      Description: Footer set

      Date            Modified by       Description    
      03/11/19        Roldan Mazo       Modify Initialization with In-App Data
      3/12/2019       Roldan Mazo       Modify In-App, include Null in the condition
      1/20/2020       Katrina Narag     Add UBS SSO links
  **********************************************************************************/
  getFooterStaticContent(){
    try {
      let legalNotice = this.globals.staticLegalNotice;
      this.staticContentService.getBluePageStaticContent(legalNotice).subscribe((data) => {
        try {
          let body = data[0];
          this.legalNotice = body.Value;

          this.UBSlink = this.sanitizer.bypassSecurityTrustUrl(env.ubsFormerUrl);
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
        error.error.message += errorLocation;
        throw error.error;
      }); 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getFooterStaticContent");
      error.message += errorLocation;
      throw error;
    }
  }

  goToESO(){
    console.log('after click button, inside goToESO function');
    try {
      console.log('clearing local storage');
      localStorage.clear();
      sessionStorage.removeItem("msal.interaction.status"); // Add this line to delete the specific session storage value
      sessionStorage.setItem("isPPAccepted","");
      if (sessionStorage.getItem(this.globals.isUserLoginUpdated) != "true"){
        console.log('inside gotoESO TRUE IF statement')
        sessionStorage.setItem(this.globals.isUserLoginUpdated, "false");
        sessionStorage.setItem("First Time Login","True");
        //this.runAuthentication();
        this.authService.loginRedirect();
        //this.navToPage('homepage');

      } else {
        console.log('inside gotoESO ELSE statement')
        this.navToPage('homepage');
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "goToESO");
      error.message += errorLocation;
      throw error;
    }
  }

  // mockGoToESO(){
  //   console.log('after click button, inside MOCKgoToESO function');
  //   console.log('Clearing local storage');
  //   localStorage.clear();
  //   try {
  //     sessionStorage.removeItem("msal.interaction.status"); // Add this line to delete the specific session storage value
  //     sessionStorage.setItem("isPPAccepted","");
  //     if (sessionStorage.getItem(this.globals.isUserLoginUpdated) != "true"){
  //       console.log('inside MOCKgotoESO TRUE IF statement')
  //       sessionStorage.setItem(this.globals.isUserLoginUpdated, "false");
  //       sessionStorage.setItem("First Time Login","True");
  //       //this.runAuthentication();
  //       this.authService.loginRedirect();
  //       //this.navToPage('homepage');

  //     } else {
  //       console.log('inside MOCKgotoESO ELSE statement')
  //       this.navToPage('homepage');
  //     }
  //   } catch(error) {
  //     let errorLocation = this.errorLocation.replace("methodName", "goToESO");
  //     error.message += errorLocation;
  //     throw error;
  //   }
  // }

  // clearAndReload(){
  //   window.location.reload(); 
  //   sessionStorage.clear();
  //   localStorage.clear();
  // }
  
  getBluePageStaticContent(){
    try {
      let emplNotice = this.globals.staticFormerEmplNotice;

      this.staticContentService.getBluePageStaticContent(emplNotice).subscribe((data) => { 
        try {
          let body =  data[0];      
  
          this.formerEmployeeName = body.Name;
          this.formerEmployeeValue = body.Value;
          this.loading = false;
          // this.samplejQuery();
        } catch(error) {
          let errorLocation = this.errorLocation.replace("methodName", "getBluePageStaticContent");
          error.message += errorLocation;
          throw error;
        }
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getBluePageStaticContent");
        error.error.message += errorLocation;
        throw error.error;
      });
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getBluePageStaticContent");
      error.message += errorLocation;
      throw error;
    }
  }


/**********************************************************************************
      Method Name: getInAppNotificationContent method
      Description: Get In-App Data and separate the message in 3 parts

      Date            Modified by       Description    
      03/11/19        Roldan Mazo       Retrieve In-App Data
**********************************************************************************/

  getInAppNotificationContent(){
    try {
      this.staticContentService.getInAppNotifContent().subscribe((data) => {
        try{          
          let InAppData = data; 
          let inAppstore = sessionStorage.getItem('In App Stat'); 
          // this.inAppMsg = JSON.parse(InAppData.body);     
          this.inAppMsg = InAppData;                               
  
          if ((this.inAppMsg == null) || (inAppstore == '0')){
            this.showInApp = false;
          } else {
            this.showInApp = true;
          }              
        }
          catch(error) {
            let errorLocation = this.errorLocation.replace("methodName", "getInAppNotificationContent");
            error.message = error.message + errorLocation;
            throw error;
          }     
      }, error => {
        let errorLocation = this.errorLocation.replace("methodName", "getInAppNotificationContent");
        error.error.message += errorLocation;
        throw error.error;
      }); 
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "getInAppNotificationContent");
      error.message += errorLocation;
      throw error;
    }
  }

  /**********************************************************************************
      Method Name: inAppSession method
      Description: Set In-App data input from user and store if already read

      Date            Modified by       Description    
      03/11/19        Roldan Mazo       Store In-App Status
**********************************************************************************/

  inAppSession(inappStat){ 
    try {
      if (inappStat == 0){
        sessionStorage.setItem('In App Stat', inappStat);
      }
    } catch(error) {
      let errorLocation = this.errorLocation.replace("methodName", "navToPage");
      error.message += errorLocation;
      throw error;
    }
  }

  runAuthentication() {
    const self = this; 
      //MAM TRY
      sessionStorage.setItem("First Time Login","True");
       try{
         self.msalService.isUserAuthenticated();
      }
      catch(err){
        const { message } = err;
        self.hasError = true;
        self.appMessage = 'Something went wrong. ' + (message ? message : 'Server error');

        let errorLocation = this.errorLocation.replace("methodName", "runAuthentication");
        err.message += errorLocation;
        throw err;
      }
  }

  getDatafrommyResources(){
    // this.staticContentService.getDatafrommyR().map(res => res).subscribe(data => {   
    //   this.bellGrantList = data;
           
    //   this.myResourcesData = Array.of(data.json());
    //   this.myResourcesData = JSON.parse(this.myResourcesData[0].body);
    //   this.myResourcesData = this.myResourcesData["data"];     
    // });
  }

  /**********************************************************************************
   * Method Name: checkErrorMessage                                                                 
   * Description: Checks the errors encountered in the homepage component                               
   *                                                                                          
   * Date                 Modified by                 Description                             
   * 01/31/19             Dalemark P. Suquib          Initial draft          
   *********************************************************************************/
  checkErrorMessage() {
    this.errorNotificationService.notification$.subscribe(error => {
      try {
        let errorMsg: string, errorLocation: string, webPage: string;
        let errorData = [];
        
        if(error != null && error != undefined) {
          for(let i = 0; i < error.length; i++) {
            if(error[i] != undefined) {
              errorMsg = error[i].toString().substring(0, error[i].toString().indexOf("+") - 1);
              errorLocation = error[i].toString().substring(error[i].toString().lastIndexOf("+") + 2);

              if(errorLocation.indexOf("Landingpage") != -1) {
                webPage = "Landingpage";
                errorData.push(null);
                errorData.push(webPage);
                errorData.push(errorMsg);
                this.errorNotificationService.logError(errorData).subscribe();
              }
            }
          }
        
          this.errorNotificationService.refreshErrorList();
        }
      } catch(error) {
        let errorLocation = this.errorLocation.replace("methodName", "checkErrorMessage");
        error.message = error.message + errorLocation;
        throw error;
      }
    });
  }



/**********************************************************************************
  * Method Name: getPortalValues                                                                 
  * Description: Radio button employee type selector                              
  *                                                                                          
  * Date                 Modified by                      Description                             
  * 06/19/19             Jayson Sevilla                   Initial draft       
 *********************************************************************************/
getPortalValues($event){
  this.isProceedButtonDisabled = false;
   if($event == "active"){
     this.selectedEmployeeType = "Active";
   }
   else{
     this.selectedEmployeeType = "Former";
   }
}

/**********************************************************************************
  * Method Name: goToPortal                                                                 
  * Description: Proceed to people page or myrequest page                              
  *                                                                                          
  * Date                 Modified by                      Description                             
  * 06/19/19             Jayson Sevilla                   Initial draft       
 *********************************************************************************/
goToPortal(){
  if (this.selectedEmployeeType == "Active"){
    if(this.globals.stageName == "prod"){
      window.open("https://workday.accenture.com", "_blank");
      }
    else{
      window.open("https://wd3-impl.workday.com/accenture10/d/home.htmld", "_blank");
      }
  } else if (this.selectedEmployeeType == "Former"){
    window.open("https://support.accenture.com/former_employee_portal", "_blank");
  }
}

showModal(){
  try {
    document.getElementById("hereModal").click();
  } catch(error) {
    // sessionStorage.clear();
    // this.navToPage("landingpage");
    let errorLocation = this.errorLocation.replace("methodName", "showModal");
    error.message += errorLocation;
    throw error;
  }
}

backModal(){
  try {
    document.getElementById("backModal").click();
  } catch(error) {
    // sessionStorage.clear();
    // this.navToPage("landingpage");
    let errorLocation = this.errorLocation.replace("methodName", "showModal");
    error.message += errorLocation;
    throw error;
  }
}


footerModal(){
  try {
    document.getElementById("footerModal").click();
  } catch(error) {
    // sessionStorage.clear();
    // this.navToPage("landingpage");
    let errorLocation = this.errorLocation.replace("methodName", "showModal");
    error.message += errorLocation;
    throw error;
  }
}

agreementValue($event){
  this.isProceedButtonDisabled = false;
  if($event == "accepted"){
    this.privacyPolicyUserAgreement = true;
  }else{
    this.privacyPolicyUserAgreement = false;
  }
}

setNavbarSticky(){

  var navbar = document.getElementById("lpNav");
  var sticky = navbar.offsetTop;

  $(window).scroll(function() {
    if (window.pageYOffset >= sticky) {
      navbar.classList.add("sticky")
    } else {
      navbar.classList.remove("sticky");
    }
  });
}





}
