import { BrowserModule, Title  } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
// import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap/dropdown/dropdown';
// import { Ng5SliderModule } from 'ng5-slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { HttpInterceptorService } from './services/HttpInterceptorService';
import { MsalInterceptor } from './services/MsalInterceptor';
import { GlobalExceptionHandlerService } from './services/GlobalExceptionHandlerService';
// import { AuthService } from './services/auth/auth.service';
import { AppConfig }  from './services/auth/app.config';
import { StaticContentService} from './services/StaticContentService';
import { HighlightsService } from './highlightspage/HighlightsService';
import { UserService } from './services/UserService';
import { MarketQuoteService } from './services/MarketQuoteService';
import { HoldingsSummaryService } from './holdingsummarypage/HoldingSummaryService';
import { HoldingProgramDetailsService } from './holdingprogramdetailspage/HoldingProgramDetailsService';

import { Globals } from './globals';
import * as $ from 'jquery';
import { NumberDirective } from './directives/numbers-only.directive'; 
import { LetterDirective } from './directives/letters-only.directive'; 
import { PersonnelNumberDirective } from './directives/personnel-number-directive';
import { TwoDigitDecimaNumberDirective } from './directives/two-digit-decima-number.directive'; 
import { OverrideCurrentSharePriceDirective } from './directives/override-current-share-price.directive'; 
import { TrillionTextDirective } from './directives/trillion-text.directive'; 


// import { AuthModule } from './services/auth/auth.module';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NavheaderComponent } from './navheader/navheader.component';
import { ProfilepageComponent } from './profilepage/profilepage.component';
import { HighlightspageComponent } from './highlightspage/highlightspage.component';
import { PagefooterComponent } from './pagefooter/pagefooter.component';
import { BrokersPageComponent } from './brokerspage/brokerspage.component';
import { UserinfoheaderComponent } from './userinfoheader/userinfoheader.component';
import { TechnologySupportComponent } from './technologysupport/technologysupport.component';
import { UserInfoDetailsResolve } from './services/UserInfoDetails.resolve';
import { TokenService } from './services/TokenService';
import { HoldingsummarypageComponent } from './holdingsummarypage/holdingsummarypage.component';
import { AdminpageComponent } from './adminpage/adminpage.component';
import { HoldingprogramdetailspageFounderComponent } from './holdingprogramdetailspage-founder/holdingprogramdetailspage-founder.component';
import { HoldingprogramdetailspageComponent } from './holdingprogramdetailspage/holdingprogramdetailspage.component';
import { EnrollmentwithdrawEsppComponent } from './enrollmentwithdraw-espp/enrollmentwithdraw-espp.component';
import { EnrollmentwithdrawVeipComponent } from './enrollmentwithdraw-veip/enrollmentwithdraw-veip.component';

import { HoldingprogramdetailspageEsppComponent } from './holdingprogramdetailspage-espp/holdingprogramdetailspage-espp.component';
import { HoldingprogramdetailspageVeipComponent } from './holdingprogramdetailspage-veip/holdingprogramdetailspage-veip.component';
import { HoldingProgramDetailsESPPService } from './holdingprogramdetailspage-espp/HoldingProgramDetailsESPPService';
import { HoldingProgramDetailsVEIPService } from './holdingprogramdetailspage-veip/HoldingProgramDetailsVEIPService';
import { HoldingProgramDetailsFounderService } from './holdingprogramdetailspage-founder/HoldingProgramDetailsFounderService';

import { HoldingprogramdetailspageOptionsComponent } from './holdingprogramdetailspage-options/holdingprogramdetailspage-options.component';
import { PerformanceawardpageComponent } from './performanceawardpage/performanceawardpage.component';
import { HoldingProgramDetailsOptionsService } from './holdingprogramdetailspage-options/HoldingProgramDetailsOptionsService';;
import { HoldingprogramdetailspageRsuComponent } from './holdingprogramdetailspage-rsu/holdingprogramdetailspage-rsu.component';
import { HoldingprogramdetailsRsuService } from './holdingprogramdetailspage-rsu/HoldingProgramDetailsRSUService';
import { HoldingProgramdetailspageRsuIpoComponent } from './holdingprogramdetailspage-rsu-ipo/holdingprogramdetailspage-rsu-ipo.component';
import { HoldingProgramDetailsRSUIPOService } from './holdingprogramdetailspage-rsu-ipo/HoldingProgramDetailsRSUIPOService';
import { KeyexecComponent } from './keyexec/keyexec.component';
import { KeyexecService } from './keyexec/keyexec.service';
import { EspptransactionService } from './enrollmentwithdraw-espp/EsppTransactionService';
import { VeiptransactionService } from './enrollmentwithdraw-veip/VeipTransactionService';
import { MultipleGrantsService } from './multiplegrants/MultipleGrantsService';


import { AdminPageServices } from './adminpage/AdminPageServices';

import { PerformanceawardService } from './performanceawardpage/PerformanceAwardService';
import { ErrorNotificationService } from './services/ErrorNotificationService';
import { EorpageComponent } from './eorpage/eorpage.component';
import { EorService } from './eorpage/EorService';
import { KeyExecToggleService } from './services/KeyExecToggleService';
import { ReportsEnrollmenthistorypageComponent } from './reports-enrollmenthistorypage/reports-enrollmenthistorypage.component';
import { EnrollmenthistorypageService } from './reports-enrollmenthistorypage/EnrollmentHistoryPageService';
import { ReportspageComponent } from './reportspage/reportspage.component';
import { ReportsSharepricehistorypageComponent } from './reportspage/reports-sharepricehistorypage/reports-sharepricehistorypage.component';
import { SharePriceHistoryPageService } from './reportspage/reports-sharepricehistorypage/SharePriceHistoryPageService';
import { ReportsService } from './reportspage/ReportsService';
import { MultiplegrantsComponent } from './multiplegrants/multiplegrants.component';
import { CustomNgbDateParserFormatter } from './services/CustomNgbDateParserFormatter';
import { ResourcespageComponent } from './resourcespage/resourcespage.component';
import { BrokersPageService } from './brokerspage/BrokersPageService';
import { CountryContactDetailsDirective } from './directives/country-contact-details.directive';
import { sanitizeHtmlPipe } from './sanitize-html.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExportExcelService } from './services/ExportExcelService';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RoutingState } from './services/RoutingState';
import { GrantPrintLayoutComponent } from './grant-print-layout/grant-print-layout.component';
import { PrintService } from './services/PrintService';
import { EsppMobileComponent } from './holdingprogramdetailspage-espp/espp-mobile/espp-mobile.component';
import { EsppDesktopComponent } from './holdingprogramdetailspage-espp/espp-desktop/espp-desktop.component';
import { MobileService } from './services/MobileService';
import { ContactspageComponent } from './contactspage/contactspage.component';
import { ImportantdatesComponent } from './resourcespage/importantdates/importantdates.component';
import { TrainingandfaqsComponent } from './resourcespage/trainingandfaqs/trainingandfaqs.component';
import { LoadingmatspinnerComponent } from './reusable/loadingmatspinner/loadingmatspinner.component';
import { ErrormessageComponent } from './reusable/errormessage/errormessage.component';
import { RsuSalesHistoryComponent } from './holdingprogramdetailspage-rsu/rsu-sales-history/rsu-sales-history.component';
import { RsuLearnMoreComponent } from './holdingprogramdetailspage-rsu/rsu-learn-more/rsu-learn-more.component';
import { RsuDetailsCardComponent } from './holdingprogramdetailspage-rsu/rsu-details-card/rsu-details-card.component';
import { IporsuDetailsCardComponent } from './holdingprogramdetailspage-rsu-ipo/iporsu-details-card/iporsu-details-card.component';
import { IporsuGrantsCardComponent } from './holdingprogramdetailspage-rsu-ipo/iporsu-grants-card/iporsu-grants-card.component';
import { IporsuVestCardComponent } from './holdingprogramdetailspage-rsu-ipo/iporsu-vest-card/iporsu-vest-card.component';
import {IporsuReleaseCardComponent } from './holdingprogramdetailspage-rsu-ipo/iporsu-release-card/iporsu-release-card.component'
import { IporsuSalesHistoryComponent } from './holdingprogramdetailspage-rsu-ipo/iporsu-sales-history/iporsu-sales-history.component'
import { IporsuLearnMoreComponent } from './holdingprogramdetailspage-rsu-ipo/iporsu-learn-more/iporsu-learn-more.component';
import { RsuVestCardComponent } from './holdingprogramdetailspage-rsu/rsu-vest-card/rsu-vest-card.component';
import { RsuRelCardComponent } from './holdingprogramdetailspage-rsu/rsu-rel-card/rsu-rel-card.component';
import { RsuDivCardComponent } from './holdingprogramdetailspage-rsu/rsu-div-card/rsu-div-card.component';
import { OptionsDetailsCardComponent } from './holdingprogramdetailspage-options/options-details-card/options-details-card.component';
import { OptionsExercisableCardComponent } from './holdingprogramdetailspage-options/options-exercisable-card/options-exercisable-card.component'
import { OptionsSalableCardComponent } from './holdingprogramdetailspage-options/options-salable-card/options-salable-card.component';
import { OptionsVestCardComponent } from './holdingprogramdetailspage-options/options-vest-card/options-vest-card.component';
import { ProgramguidebooksComponent } from './resourcespage/programguidebooks/programguidebooks.component';
import { EquitycommunicationsComponent } from './resourcespage/equitycommunications/equitycommunications.component';
import { CountryspecifictaxinformationsComponent } from './resourcespage/countryspecifictaxinformations/countryspecifictaxinformations.component';
import { TaxtimeComponent } from './resourcespage/taxtime/taxtime.component';
import { ToolsandreportsComponent } from './resourcespage/toolsandreports/toolsandreports.component';
import { PoliciesandotherlinksComponent } from './resourcespage/policiesandotherlinks/policiesandotherlinks.component';
import { GlossaryComponent } from './resourcespage/glossary/glossary.component';
import { TopquestionsComponent } from './resourcespage/topquestions/topquestions.component';
import { AwardDetailsSummaryComponent } from './reusable/award-details-summary/award-details-summary.component';
import { OptionsLearnmoreTabComponent } from './holdingprogramdetailspage-options/options-learnmore-tab/options-learnmore-tab.component';
import { VeipDetailsCardComponent } from './holdingprogramdetailspage-veip/veip-details-card/veip-details-card.component';
import { VeipMonthlypurchaseCardComponent } from './holdingprogramdetailspage-veip/veip-monthlypurchase-card/veip-monthlypurchase-card.component';
import { LoadingService } from './services/LoadingService';
import { NotfoundComponent } from './notfound/notfound.component';
import { OptionsAgreementComponent } from './holdingprogramdetailspage-options/options-agreement/options-agreement.component';
import { OptionsExercisehistoryTabComponent } from './holdingprogramdetailspage-options/options-exercisehistory-tab/options-exercisehistory-tab.component';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from './services/CustomDateAdapter';
import { VeipLearnmoreTabComponent } from './holdingprogramdetailspage-veip/veip-learnmore-tab/veip-learnmore-tab.component';
import { VeipSaleshistoryTabComponent } from './holdingprogramdetailspage-veip/veip-saleshistory-tab/veip-saleshistory-tab.component';
import { DividendComponent } from './resourcespage/dividend/dividend.component';
import { MsalGuardService } from './services/msal/msal-guard.service';
// import { MSAL_CONFIG } from '@azure/msal-angular';
import { getMsalConfig } from './services/MsalConfigurator';



// Equity Admin
import { EquityadminComponent } from './equityadmin/equityadmin.component';
import { EjetComponent } from './equityadmin/iea/ejet/ejet.component';
import { EamainComponent } from './equityadmin/eamain/eamain.component';
import { ApiService } from './services/ea/api/ApiService';
import { NgxsModule } from '@ngxs/store';
import { StaticService } from './services/ea/api/StaticService';
import { CreatereportsComponent } from './equityadmin/iea/createreports/createreports.component';
import { ImportinvoiceComponent } from './equityadmin/iea/ejet/importinvoice/importinvoice.component';
import { WeeklysummaryComponent } from './equityadmin/iea/ejet/weeklysummary/weeklysummary.component';
import { SummaryreportsComponent } from './equityadmin/iea/ejet/weeklysummary/summaryreports/summaryreports.component';
import { SumreportstableComponent } from './equityadmin/iea/ejet/weeklysummary/summaryreports/sumreportstable/sumreportstable.component';
import { DataapprovalComponent } from './equityadmin/iea/ejet/weeklysummary/dataapproval/dataapproval.component';
import { ReportItemState, StaticTextState } from './services/ea/states/weeklysummary.state';
import { LoadingComponent } from './services/ea/shared/loading/loading.component';
import { SemComponent } from './equityadmin/sem/sem.component';
import { PdsaComponent } from './equityadmin/sem/pdsa/pdsa.component';
import { MatPaginatorIntlPDSA } from './equityadmin/sem/pdsa/pdsaPaginator';
import { ApplicationaccessService } from './services/ea/api/ApplicationaccessService';
import { ToolaccessService } from './services/ea/api/ToolaccessService';
import { RoleaccessService } from './services/ea/api/RoleaccessService';
import { EquityadminGuard } from './services/ea/guard/equityadmin.guard';
import { EaLoadingService } from './services/ea/EaLoadingService';
import { ModalComponent } from './services/ea/shared/modal/modal.component';
import { LoadingOverlayComponent } from './services/ea/shared/loading-overlay/loading-overlay.component';
import { DataapptableComponent } from './equityadmin/iea/ejet/weeklysummary/dataapproval/dataapptable/dataapptable.component';
import { DataAppComboItemState, DataAppCountryState, DataAppItemState } from './services/ea/states/dataapproval.state';
import { PreventDoubleClickDirective } from './directives/prevent-multiple-click.directive';
import { FileService } from './services/ea/FileService';
import { EjetaccessService } from './services/ea/EjetaccessService';
import { DatepickerComponent, CustomEaDateFormat } from './services/ea/shared/datepicker/datepicker.component';
import { AlphanumericOnlyDirective } from './directives/alphanumeric-only.directive';
import { BdrComponent } from './equityadmin/iea/bdr/bdr.component';
import { AccountnumberComponent } from './equityadmin/iea/bdr/accountnumber/accountnumber.component';
import { AnfieldinfoComponent } from './equityadmin/iea/bdr/accountnumber/anfieldinfo/anfieldinfo.component';
import { BilltoComponent } from './equityadmin/iea/bdr/billto/billto.component';
import { BtfieldinfoComponent } from './equityadmin/iea/bdr/billto/btfieldinfo/btfieldinfo.component';
import { CompanycodeComponent } from './equityadmin/iea/bdr/companycode/companycode.component';
import { CcfieldinfoComponent } from './equityadmin/iea/bdr/companycode/ccfieldinfo/ccfieldinfo.component';
import { InvoicedescriptionComponent } from './equityadmin/iea/bdr/invoicedescription/invoicedescription.component';
import { IdfieldinfoComponent } from './equityadmin/iea/bdr/invoicedescription/idfieldinfo/idfieldinfo.component';
import { BdrLoadingService } from './services/ea/api/BdrLoadingService';
import { BdraccessService } from './services/ea/api/BdrAccessService';
import { InvoiceDescItemState, InvoiceDescListState, InvoiceProfitCenterState } from './services/ea/states/invoicedescription.state';
import { PartnerselectedComponent } from './equityadmin/sem/pdsa/partnerselected/partnerselected.component';
import { PersonaldataComponent } from './equityadmin/sem/pdsa/partnerselected/personaldata/personaldata.component';
import { PerfunitmodalComponent } from './services/ea/shared/perfunitmodal/perfunitmodal.component';
import { PartnerselectedsearchComponent } from './equityadmin/sem/pdsa/partnerselected/partnerselectedsearch/partnerselectedsearch.component';
import { ProjectedchangesComponent } from './equityadmin/sem/pdsa/partnerselected/projectedchanges/projectedchanges.component';
import { LegaldocumentsComponent } from './equityadmin/sem/pdsa/partnerselected/legaldocuments/legaldocuments.component';
import { ReportsComponent } from './equityadmin/sem/pdsa/reports/reports.component';
import { EducationComponent } from './equityadmin/sem/pdsa/partnerselected/education/education.component';
import { UnithistoryComponent } from './equityadmin/sem/pdsa/partnerselected/unithistory/unithistory.component';
import { UnithistorytableComponent } from './equityadmin/sem/pdsa/partnerselected/unithistory/unithistorytable/unithistorytable.component';
import { NotesComponent } from './equityadmin/sem/pdsa/partnerselected/notes/notes.component';
import { RiComponent } from './equityadmin/sem/pdsa/partnerselected/ri/ri.component';
import { UnitawardComponent } from './equityadmin/sem/pdsa/partnerselected/unitaward/unitaward.component';
import { CompCodeItemState } from './services/ea/states/compcode.state';
import { AccountNumberItemState } from './services/ea/states/accountnum.state';
import { BillToItemState, StaticDataState } from './services/ea/states/billto.state';
import { SemaccessService } from './services/ea/api/SemaccessService';
import { SemlinkaccessService } from './services/ea/api/SemlinkaccessService';
import { MemberfirmComponent } from './equityadmin/sem/pdsa/partnerselected/memberfirm/memberfirm.component';
import { ChangelogComponent } from './equityadmin/sem/pdsa/partnerselected/changelog/changelog.component';
import { MatPaginatorIntlChangeLog } from './equityadmin/sem/pdsa/partnerselected/changelog/changelogPaginator';
import { BusinessinformationComponent } from './equityadmin/sem/pdsa/partnerselected/businessinformation/businessinformation.component';
import { EaerrormessageComponent } from './services/ea/shared/eaerrormessage/eaerrormessage.component';
import { EaUserAccessService } from './services/ea/EaUserAccessService';
import { SemLetterDirective } from './directives/sem-letter-only.directive';
import { SemNumberDirective } from './directives/sem-numbers-only.directive';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalRedirectComponent } from './services/msal/msal.redirect.component';
import { MsalGuard, MsalService, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule,
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { CanActivateGuard } from './services/msal/msal.guard';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 ||
             window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: clientId(clientId),
      authority: authority(authority),
      redirectUri: redirectUri(redirectUri),
      postLogoutRedirectUri: postLogoutRedirectUri(postLogoutRedirectUri),
      navigateToLoginRequestUrl: true,
      knownAuthorities: knownAuthorities(knownAuthorities)
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function clientId(clientId: any): string {
  if (window.location.toString().indexOf('https://myholdings.accenture.com/') > -1) {
    return clientId = '77d56f01-b168-4ff1-953d-c5176edc69f9';
  } else {
    return clientId = '2cb02e3a-1122-49eb-85e4-9fad772b2b03';
    //return clientId = 'be090759-d3e7-4d76-bfdb-48b4c23ed1e8';
  }
}

export function authority(authority: any): string {
  if (window.location.toString().indexOf('https://myholdings.accenture.com/') > -1) {
    return authority = 'https://login-former.accenture.com/12648877-392f-4d24-bc45-238885f09b7c/b2c_1a_signin_oidc';
  } else {
    return authority = 'https://b2cformerstg.b2clogin.com/b2cformerstg.onmicrosoft.com/b2c_1a_signin_oidc';
  }
}

export function redirectUri(redirectUri: any): string {
  if (window.location.toString().indexOf('https://myholdings.accenture.com/') > -1) {
    return redirectUri = 'https://myholdings.accenture.com/homepage';
  } else if(window.location.toString().indexOf('https://myholdings.ciostage2.accenture.com/') > -1) {
    return redirectUri = 'https://myholdings.ciostage2.accenture.com/homepage';
  } else {
    return redirectUri = 'https://myholdings-pt.ciotest.accenture.com/homepage';
  }
}

export function postLogoutRedirectUri(postLogoutRedirectUri: any): string {
  if (window.location.toString().indexOf('https://myholdings.accenture.com/') > -1) {
    return postLogoutRedirectUri = 'https://myholdings.accenture.com/homepage';
  } else if(window.location.toString().indexOf('https://myholdings.ciostage2.accenture.com/') > -1) {
    return postLogoutRedirectUri = 'https://myholdings.ciostage2.accenture.com/homepage';
  } else {
    return postLogoutRedirectUri = 'https://myholdings-pt.ciotest.accenture.com/homepage';
  }
}

export function knownAuthorities(knownAuthorities: any) {
  if (window.location.toString().indexOf('https://myholdings.accenture.com/') > -1) {
    return knownAuthorities = ['https://login-former.accenture.com/B2CformerPROD.onmicrosoft.com/b2c_1a_signin_oidc/oauth2/v2.0/authorize'];
  } else if(window.location.toString().indexOf('https://myholdings.ciostage.accenture.com/') > -1) {
    return knownAuthorities = ['https://b2cformerstg.b2clogin.com/b2cformerstg.onmicrosoft.com/b2c_1a_signin_oidc/oauth2/v2.0/authorize'];
  } else {
    return knownAuthorities = ['https://b2cformerstg.b2clogin.com/b2cformerstg.onmicrosoft.com/b2c_1a_signin_oidc/oauth2/v2.0/authorize'];
  }
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  let graphAPI;

  if (window.location.toString().indexOf('https://myholdings.accenture.com/') > -1) {
    graphAPI = 'https://graph.microsoft.com/v1.0/me';
  } else {
    graphAPI = 'https://graph.microsoft-ppe.com/v1.0/me';
  }
  protectedResourceMap.set(graphAPI, ['user.read']);
  
return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    //loginFailedRoute: '/login-failed'
  };
}

@NgModule({
    declarations: [
        AppComponent,
        LandingpageComponent,
        HomepageComponent,
        NavheaderComponent,
        ProfilepageComponent,
        HighlightspageComponent,
        PagefooterComponent,
        BrokersPageComponent,
        UserinfoheaderComponent,
        TechnologySupportComponent,
        HoldingsummarypageComponent,
        HoldingprogramdetailspageRsuComponent,
        HoldingprogramdetailspageComponent,
        HoldingprogramdetailspageEsppComponent,
        HoldingprogramdetailspageVeipComponent,
        AdminpageComponent,
        HoldingprogramdetailspageFounderComponent,
        HoldingprogramdetailspageOptionsComponent,
        PerformanceawardpageComponent,
        HoldingProgramdetailspageRsuIpoComponent,
        SemLetterDirective,
        SemNumberDirective,
        NumberDirective,
        KeyexecComponent,
        LetterDirective,
        PersonnelNumberDirective,
        TwoDigitDecimaNumberDirective,
        OverrideCurrentSharePriceDirective,
        EnrollmentwithdrawVeipComponent,
        EnrollmentwithdrawEsppComponent,
        ReportsEnrollmenthistorypageComponent,
        ReportspageComponent,
        ReportsSharepricehistorypageComponent,
        MultiplegrantsComponent,
        TrillionTextDirective,
        ResourcespageComponent,
        CountryContactDetailsDirective,
        EorpageComponent,
        GrantPrintLayoutComponent,
        EsppMobileComponent,
        EsppDesktopComponent,
        ContactspageComponent,
        ImportantdatesComponent,
        TrainingandfaqsComponent,
        LoadingmatspinnerComponent,
        ErrormessageComponent,
        sanitizeHtmlPipe,
        RsuSalesHistoryComponent,
        RsuLearnMoreComponent,
        sanitizeHtmlPipe,
        RsuDetailsCardComponent,
        RsuVestCardComponent,
        RsuRelCardComponent,
        RsuDivCardComponent,
        IporsuDetailsCardComponent,
        IporsuGrantsCardComponent,
        IporsuVestCardComponent,
        IporsuReleaseCardComponent,
        IporsuLearnMoreComponent,
        IporsuSalesHistoryComponent,
        OptionsExercisableCardComponent,
        OptionsSalableCardComponent,
        OptionsVestCardComponent,
        OptionsDetailsCardComponent,
        sanitizeHtmlPipe,
        RsuDivCardComponent,
        ProgramguidebooksComponent,
        EquitycommunicationsComponent,
        CountryspecifictaxinformationsComponent,
        TaxtimeComponent,
        ToolsandreportsComponent,
        PoliciesandotherlinksComponent,
        GlossaryComponent,
        TopquestionsComponent,
        AwardDetailsSummaryComponent,
        OptionsLearnmoreTabComponent,
        VeipDetailsCardComponent,
        VeipMonthlypurchaseCardComponent,
        OptionsAgreementComponent,
        OptionsExercisehistoryTabComponent,
        VeipLearnmoreTabComponent,
        VeipSaleshistoryTabComponent,
        DividendComponent,
        NotfoundComponent,
        EquityadminComponent,
        EamainComponent,
        CreatereportsComponent,
        ImportinvoiceComponent,
        WeeklysummaryComponent,
        SummaryreportsComponent,
        SumreportstableComponent,
        DataapprovalComponent,
        LoadingComponent,
        SemComponent,
        PdsaComponent,
        PdsaComponent,
        EjetComponent,
        ModalComponent,
        PreventDoubleClickDirective,
        LoadingOverlayComponent,
        DataapptableComponent,
        DatepickerComponent,
        EjetComponent,
        AlphanumericOnlyDirective,
        AccountnumberComponent,
        AnfieldinfoComponent,
        BilltoComponent,
        BtfieldinfoComponent,
        CompanycodeComponent,
        CcfieldinfoComponent,
        InvoicedescriptionComponent,
        IdfieldinfoComponent,
        PartnerselectedComponent,
        PersonaldataComponent,
        PerfunitmodalComponent,
        PartnerselectedsearchComponent,
        ProjectedchangesComponent,
        LegaldocumentsComponent,
        ReportsComponent,
        BdrComponent,
        EducationComponent,
        UnithistoryComponent,
        UnithistorytableComponent,
        NotesComponent,
        RiComponent,
        UnitawardComponent,
        MemberfirmComponent,
        ChangelogComponent,
        BusinessinformationComponent,
        EaerrormessageComponent,
        BdrComponent,
        CustomEaDateFormat,
        MsalRedirectComponent
    ],
    exports: [
        AppComponent,
    ],
    imports: [
        MatTooltipModule,
        BrowserModule,
        // HttpModule,
        AppRoutingModule,
        HttpClientModule,
        // AuthModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatSortModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatFormFieldModule,
        MatSliderModule,
        // Ng5SliderModule,
        NgxSliderModule,
        MatRadioModule,
        MsalModule,
        MatDialogModule,
        NgbModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '3px',
            primaryColour: '#460173',
            secondaryColour: '#A380B9',
            tertiaryColour: '#000000'
        }),
        NgxsModule.forRoot([
            ReportItemState,
            StaticTextState,
            DataAppComboItemState,
            DataAppCountryState,
            DataAppItemState,
            InvoiceDescItemState,
            InvoiceDescListState,
            InvoiceProfitCenterState,
            CompCodeItemState,
            AccountNumberItemState,
            BillToItemState,
            StaticDataState,
        ])
    ],
    providers: [
        // AuthService,
        AppConfig,
        StaticContentService,
        HighlightsService,
        UserService,
        Globals,
        UserInfoDetailsResolve,
        TokenService,
        MarketQuoteService,
        HoldingsSummaryService,
        HoldingProgramDetailsService,
        HoldingProgramDetailsESPPService,
        HoldingProgramDetailsVEIPService,
        HoldingProgramDetailsFounderService,
        HoldingProgramDetailsOptionsService,
        HoldingprogramdetailsRsuService,
        HoldingProgramDetailsRSUIPOService,
        KeyexecService,
        AdminPageServices,
        PerformanceawardService,
        EspptransactionService,
        VeiptransactionService,
        ErrorNotificationService,
        EorService,
        KeyExecToggleService,
        EnrollmenthistorypageService,
        SharePriceHistoryPageService,
        ExportExcelService,
        RoutingState,
        ReportsService,
        MultipleGrantsService,
        BrokersPageService,
        PrintService,
        MobileService,
        MsalService,
        MsalGuardService,
        MsalGuard,
        MsalBroadcastService,
        LoadingService,
        ApiService,
        StaticService,
        ApplicationaccessService,
        ToolaccessService,
        RoleaccessService,
        EquityadminGuard,
        EaLoadingService,
        EaUserAccessService,
        FileService,
        EjetaccessService,
        BdrLoadingService,
        BdraccessService,
        SemaccessService,
        SemlinkaccessService,
        CanActivateGuard,
        {
            provide: NgbDateParserFormatter,
            useFactory: () => new CustomNgbDateParserFormatter('DD MMM YYYY')
        },
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: (config: AppConfig) => () => config.load(),
        //   deps: [ AppConfig ], multi: true 
        // }, 
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: HttpInterceptorService,
        //   multi: true
        // },
        // {
        //     provide: MSAL_GUARD_CONFIG,
        //     useValue: getMsalConfig()
        // },
        {
            provide: ErrorHandler,
            useClass: GlobalExceptionHandlerService
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: CUSTOM_DATE_FORMATS
        },
        Title,
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlPDSA
        },
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlChangeLog
        },
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
